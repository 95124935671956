import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect} from 'react-router-dom';
import Main from './main.jsx';
import NotFound from './pages/404.jsx';
import Success from './pages/success.jsx';
import Cancel from './pages/cancel.jsx';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={Main}/>
            <Route exact path="/success" component={Success}/>
            <Route exact path="/cancel" component={Cancel}/>
            <Route component={NotFound}/>
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
