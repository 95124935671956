import React from 'react';
import '../App.css';
import 'bulma/css/bulma.min.css';
import { useHistory } from 'react-router-dom';

function NotFound() {
    let history = useHistory();
    return(
        <main>
            <h1 className="subtitle is-1">Page Not Found</h1>
            <p className="subtitle is-4">The page you are accessing does not exist.</p>
            <button class="button is-rounded btn-color has-text-white" onClick={() => {history.push("/")}}>Back to Main</button>
            {/* <p>[link 1] [link 2]</p> */}
        </main>
    )
}
export default NotFound;