import React from 'react'

const FullPageLoader = () => {
    return (
        <div className="fp-container">
            <img src="images/loader.gif" className="fp-loader" alt="loading"/>
        </div>
    )
}

export default FullPageLoader;