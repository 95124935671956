import React from 'react';
import '../App.css'
import 'bulma/css/bulma.min.css';
import { useHistory, useLocation } from 'react-router-dom';

function Success() {
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const transactionId = searchParams.get('transactionId')
    const referenceId = searchParams.get('referenceId')
    const customerName = searchParams.get('customerName')
    const paymentMethodCode = searchParams.get('paymentMethodCode')
    const totalAmount = searchParams.get('totalAmount')
    let history = useHistory();

    return(
        <main>
            {/* <img src="images/aqwire-logo.png" className="logo mt-1 mb-6" /> */}
            <div className="card">
            <div className="card-content">
                <div className="content">
                    <center><img src="images/success-img.png" className="image is-128x128" /></center>
                    <h3 className="title is-4"><center>Transaction Success</center></h3>
                    <table className="table is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Transaction ID</th>
                                <th className="indent-right">{transactionId}</th>
                            </tr>
                            <tr>
                                <th>Reference ID</th>
                                <th className="indent-right">{referenceId}</th>
                            </tr>
                            <tr>
                                <th>Customer Name</th>
                                <th className="indent-right">{customerName}</th>
                            </tr>
                            <tr>
                                <th>Payment Method</th>
                                <th className="indent-right">{paymentMethodCode}</th>
                            </tr>
                            <tr>
                                <th>Total Amount</th>
                                <th className="indent-right">{totalAmount}</th>
                            </tr>
                        </tbody>
                    </table>
                    <button className="button is-rounded is-fullwidth btn-color has-text-white" onClick={() => {history.push("/")}}>Create New Transaction</button>
                </div>
            </div>
            </div>
        </main>
    )
}
export default Success;