import React, { useState } from 'react';
import axios, { post } from 'axios';
import validator from 'validator';
import useFullPageLoader from "./hooks/useFullPageLoader.js";
import useUnsavedChangesAlert from "./hooks/UnsavedChangesAlert";
import 'bulma/css/bulma.min.css';
import './App.css';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { IoCopy } from "react-icons/io5";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Main() {
    const {REACT_APP_PAYMENT_API_URL, REACT_APP_DEFAULT_AUTH_MERCHANT_ID,
        REACT_APP_DEFAULT_AUTH_TOKEN, REACT_APP_BASE_URL} = process.env;
    const [response, setResponse] = useState(false);
    const [state, setState] = useState ({
        token: '',
        auth: '',
        currency: '',
        amount: '',
        name: '',
        email: '',
        proj_name: '',
        category: '',
        unitNumber: '',
        success: `${REACT_APP_BASE_URL}success?`,
        cancel: `${REACT_APP_BASE_URL}cancel?`,
        callback: "http://qwikwire.free.beeceptor.com/",
        note: '',
        description: '',
        referenceNumber: '',
        orderId: ''
    });
    const [paymentState, setPaymentState] = useState("RF")
    const [errors, setErrors] = useState({});
    const [errorIdMessage, setErrorIdMessage] = useState('');
    const [errorTokenMessage, setErrorTokenMessage] = useState('');
    const [randomId, setRandomId] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [isToggle, setIsToggle] = useState(true);
    const [hide, setHide] = useState(true);
    const [country, setCountry] = useState();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [Prompt, setDirty] = useUnsavedChangesAlert();
    const payload = {
            uniqueId: randomId,
            currency: state.currency,
            amount: state.amount,
            paymentType: paymentState,
            customer: {
                name: state.name,
                email: state.email,
                mobile: country,
            },
            project: {
                name: state.proj_name,
                category: state.category,
                unitNumber: state.unitNumber
            },
            redirectUrl: {
                success: state.success,
                cancel: state.cancel,
                callback: state.callback
            },
            note: state.note,
            description: state.description,
            metadata: {
                referenceNumber: state.referenceNumber,
                orderId: state.orderId
            }
        };
    const headers = {
        'Qw-Merchant-Id': state.auth || REACT_APP_DEFAULT_AUTH_MERCHANT_ID,
        'Authorization': state.token || `Bearer ${REACT_APP_DEFAULT_AUTH_TOKEN}`
    };
    
    function postRequest(event) {
        event.preventDefault();
        showLoader();
        post(REACT_APP_PAYMENT_API_URL, payload, {headers})
            .then((res) => {
                hideLoader()
                setHide(!hide)
                window.scrollTo(0, 0)
                console.log(res)
                console.log(res.data)
                setResponse(res.data)
                setErrorIdMessage('')
                setErrorTokenMessage('')
            })
            .catch((err) =>{
                hideLoader()
                window.scrollTo(0, 0)
                console.log(err)
                console.log(err.response.data)
                console.log(err.response.data.message)
                setErrors(validate(state));
                if (err.response.data.message === "Merchant not found") {
                    setErrorIdMessage("Merchant not found")
                } else if (err.response.data.message === "Invalid Merchant token") {
                    setErrorTokenMessage("Invalid Authorization Token")
                } else if(err.response.data.message === "Invalid api credentials") {
                    setErrorTokenMessage("Invalid Authorization Token")
                } else if(err.response.data.message === "Invalid Authorization Token") {
                    setErrorTokenMessage("Invalid Authorization Token")
                } else {
                    setErrorTokenMessage('')
                    setErrorIdMessage('')
                }
            })
    }

    function validate(state) {
        let errors = {};
        
        if(!randomId) {
            errors.uniqueId = 'Unique ID Required';
        }

        if(!state.amount) {
            errors.amount = 'Amount Required';
        } else if (!/[0-9]/.test(state.amount) || state.amount <=0)  {
            errors.amount = 'Invalid Amount';
        } 
        
        if(!state.name) {
            errors.name = 'Name Required';
        }

        if(!state.email) {
            errors.email = 'Email Required';
        } else if (!validator.isEmail(state.email)) {
            errors.email = 'Invalid Email'
        }
        
        if(!country) {
            errors.mobile = 'Invalid Mobile Number';
        }

        if(!state.success) {
            errors.success = 'Success URL Required';
        } else if (!validator.isURL(state.success)) {
            errors.success = 'Invalid URL'
        }

        if(!state.cancel) {
            errors.cancel = 'Cancel URL Required';
        } else if (!validator.isURL(state.cancel)) {
            errors.cancel = 'Invalid URL'
        }

        if(!state.callback) {
            errors.callback = 'Callback URL Required';
        } else if (!validator.isURL(state.callback)) {
            errors.callback = 'Invalid URL'
        }

        return errors;
    }

    function handleChange(event) {
        const {name, value} = event.target;
        setState({
            ...state,
            [name]: value
        })
        setDirty();
        //debugger
    };

    function enableField(e) {
        e.preventDefault();
        setDisabled(!disabled);
        setIsToggle(!isToggle);
        state.auth = '';
        state.token = '';
    };

    const generateId = () => {
        const min = 100000;
        const max = 999999;
        const date = new Date()

        const yy = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();

        const year = (yy < 1000) ? yy + 2000 : yy;
        const month = (m < 10) ? '0' + m : m;
        const day = (d < 10) ? '0' + d : d;

        const rand = Math.round(min + Math.random() * (max - min));

        setRandomId(year+month+day+'_'+rand);
    }

    return (
        <div>
            <img src="images/aqwire-logo.png" className="logo mt-6" alt="aqwire-logo"/>
            <div className="action">
                    {
                    hide?<form onSubmit={postRequest} noValidate>
                        <div className="card card-form">
                            <div className="card-content">
                                <div className="content">
                                    <h3 className="title is-3">Fill Out Details</h3>
                                    <p className="subtitle is-6 has-text-grey">Type in the necessary information. Make sure all details are correct.</p>

                                    <div className="field">
                                        <div className="field is-grouped">
                                            <label className="label label-size mt-5">Qw-Merchant-ID</label>
                                            <span className="tag is-warning is-light mt-5 ml-1">Empty credential fields are set to default</span>
                                        </div>
                                        <div className="control">
                                            <input className="input" type="text" name="auth" onChange={handleChange}
                                            value={state.auth}
                                            placeholder={REACT_APP_DEFAULT_AUTH_MERCHANT_ID}
                                            disabled={disabled}/>
                                            {errorIdMessage && <p className="help is-danger">{errorIdMessage}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Authorization</label>
                                        <div className="control">
                                            <input className="input" type="text" name="token" onChange={handleChange}
                                            value={state.token}
                                            placeholder={'Bearer ' + REACT_APP_DEFAULT_AUTH_TOKEN}
                                            disabled={disabled}/>
                                            {errorTokenMessage && <p className="help is-danger">{errorTokenMessage}</p>}
                                        </div>
                                    </div>

                                    <button className="button is-rounded is-fullwidth has-text-white btn-color mt-5 mb-5" type="button" onClick={enableField}>
                                    {isToggle ? "I have my own credentials" : "Use Default Credentials"}
                                    </button>

                                    <div className="field">
                                        <div className="field is-grouped">
                                            <label className="label label-size mt-1">Unique ID</label>
                                            <button className="button has-text-white btn-color is-rounded is-small ml-1 mt-1" type="button" onClick={generateId} >Generate Unique ID</button>
                                        </div>    
                                        <div className="control">
                                            <input className="input" type="text" name="uniqueId" value={randomId} onChange={handleChange}/>
                                            {errors.uniqueId && <p className="help is-danger">{errors.uniqueId}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                    <label className="label label-size">Amount </label>
                                        <div className="field has-addons">
                                            <div className="control">
                                                <a className="button is-static">PHP</a>
                                            </div>
                                            <div className="control is-expanded">
                                                <input className="input" type="text" name="amount" value={state.amount} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        {errors.amount && <p className="help is-danger">{errors.amount}</p>}
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Payment Type</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                            <select value={paymentState} onChange={(e) => {
                                                const selectedType = e.target.value;
                                                setPaymentState(selectedType);
                                            }}>
                                                <option value="RF" label="Reservation Fee"/>
                                                <option value="MA" label="Monthly Amortization"/>
                                                <option value="DP" label="Down Payment"/>
                                                <option value="AD" label="Association Dues"/>
                                            </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Customer Name</label>
                                        <div className="control">
                                            <input className="input" type="text" name="name" value={state.name} onChange={handleChange} />
                                            {errors.name && <p className="help is-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Email</label>
                                        <div className="control">
                                            <input className="input" type="email" name="email" value={state.email} onChange={handleChange} />
                                            {errors.email && <p className="help is-danger">{errors.email}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Mobile</label>
                                        <div className="control">
                                            <PhoneInput
                                                international
                                                defaultCountry="PH"
                                                value={country}
                                                onChange={setCountry}/>
                                            {errors.mobile && <p className="help is-danger">{errors.mobile}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Project Name</label>
                                        <div className="control">
                                            <input className="input" type="text" name="proj_name" value={state.proj_name} onChange={handleChange} />
                                            {/* {errors.proj_name && <p className="help is-danger">{errors.proj_name}</p>} */}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Category</label>
                                        <div className="control">
                                            <input className="input" type="text" name="category" value={state.category} onChange={handleChange}/>
                                            {/* {errors.category && <p className="help is-danger">{errors.category}</p>} */}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Unit Number</label>
                                        <div className="control">
                                            <input className="input" type="text" name="unitNumber" value={state.unitNumber} onChange={handleChange}/>
                                            {/* {errors.unitNumber && <p className="help is-danger">{errors.unitNumber}</p>} */}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Success</label>
                                        <div className="control">
                                            <input className="input" type="link" name="success" defaultValue={state.success} onChange={handleChange}/>
                                            {errors.success && <p className="help is-danger">{errors.success}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Cancel</label>
                                        <div className="control">
                                            <input className="input" type="text" name="cancel" defaultValue={state.cancel} onChange={handleChange}/>
                                            {errors.cancel && <p className="help is-danger">{errors.cancel}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Callback</label>
                                        <div className="control">
                                            <input className="input" type="text" name="callback" defaultValue={state.callback} onChange={handleChange}/>
                                            {errors.callback && <p className="help is-danger">{errors.callback}</p>}
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Note (Optional)</label>
                                        <div className="control">
                                            <textarea className="textarea" type="text" name="note" value={state.note} onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Description (Optional)</label>
                                        <div className="control">
                                            <input className="input" type="text" name="description" value={state.description} onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Reference Number (Metadata)</label>
                                        <div className="control">
                                            <input className="input" type="text" name="referenceNumber" value={state.referenceNumber} onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label label-size">Order ID (Metadata)</label>
                                        <div className="control">
                                            <input className="input" type="text" name="orderId" value={state.orderId} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <button className="button is-rounded is-fullwidth btn-color has-text-white mt-5" type="submit"><b>Submit</b></button>
                                </div>
                            </div>
                        </div>
                        {Prompt}
                    </form>:null
                    }
            </div>
                <div className="response">
                    <Display data={response}/>
                </div>
            {loader}
        </div>
    );
}
export default Main;

function Display(props) {
    const { data } = props;

    const refreshPage = () => {
        window.location.reload();
        window.scrollTo(0, 0)
    }

    if (!data) {
        return <div/>;
    }
    return (
        <div>
            <main>
                <div className="card card-details">
                    <div className="card-content">
                        <div className="content">
                            <h3 className="title is-3">Details</h3>
                            <div className="item-field">

                                {/* Transaction Details */}
                                <label className="label label-size">Status</label>
                                <p className="subtitle is-6">{data.status} - {data.message}</p>
                                <div className="field is-grouped">
                                    <label className="label">Payment URL</label>
                                    <CopyToClipboard text={data.paymentUrl}><button className="button is-outlined is-small ml-2"><IoCopy/></button></CopyToClipboard>
                                </div>
                                <p className="subtitle is-6"><a href={data.paymentUrl} target="_blank">{data.paymentUrl}</a></p>
                                <label className="label label-size">Merchant Name</label>
                                <p className="subtitle is-6">{data.data.merchantName}</p>
                                <label className="label label-size">Unique ID</label>
                                <p className="subtitle is-6">{data.data.uniqueId}</p>
                                <label className="label label-size">Transaction ID</label>
                                <p className="subtitle is-6">{data.data.transactionId}</p>
                                <label className="label label-size">Transaction Type</label>
                                <p className="subtitle is-6">{data.data.transactionType}</p>
                                <label className="label label-size">Amount</label>
                                <p className="subtitle is-6">{data.data.bill.base.currency} {data.data.bill.base.amount.toLocaleString()}</p>
                                <label className="label label-size">Created At</label>
                                <p className="subtitle is-6">{data.data.createdAt}</p>
                                <label className="label label-size">Expires At</label>
                                <p className="subtitle is-6">{data.data.expiresAt}</p>
                                <div className="field is-grouped">
                                    <label className="label label-size">Success URL</label>
                                    <CopyToClipboard text={data.data.redirectUrl.success}><button className="button is-outlined is-small ml-2"><IoCopy/></button></CopyToClipboard>
                                </div>
                                <p className="subtitle is-6"><a href={data.data.redirectUrl.success}>{data.data.redirectUrl.success}</a></p>
                                <div className="field is-grouped">
                                    <label className="label label-size">Callback URL</label>
                                    <CopyToClipboard text={data.data.redirectUrl.callback}><button className="button is-outlined is-small ml-2"><IoCopy/></button ></CopyToClipboard>
                                </div>
                                <p className="subtitle is-6"><a href={data.data.redirectUrl.callback}>{data.data.redirectUrl.callback}</a></p>
                                <div className="field is-grouped">
                                    <label className="label label-size">Cancel URL</label>
                                    <CopyToClipboard text={data.data.redirectUrl.cancel}><button className="button is-outlined is-small ml-2"><IoCopy/></button></CopyToClipboard>
                                </div>
                                <p className="subtitle is-6"><a href={data.data.redirectUrl.cancel}>{data.data.redirectUrl.cancel}</a></p>

                                {/* Customer Details */}
                                <h5 className="title is-5"><br></br><br></br>Customer Details</h5>
                                <label className="label label-size">Name</label>
                                <p className="subtitle is-6">{data.data.customer.name}</p>
                                <label className="label label-size">Email</label>
                                <p className="subtitle is-6">{data.data.customer.email}</p>
                                <label className="label label-size">Mobile</label>
                                <p className="subtitle is-6">{data.data.customer.mobile}</p>

                                {/* Project Details */}
                                <h5 className="title is-5"><br></br><br></br>Project Details</h5>
                                <label className="label label-size">Project</label>
                                <p className="subtitle is-6">{data.data.project.name} - {data.data.project.category}</p>
                                <label className="label label-size">Client Notes</label>
                                <p className="subtitle is-6">{data.data.clientNotes}</p>
                            </div>
                            <button className="button is-rounded is-fullwidth btn-color has-text-white mt-6" type="submit" onClick={refreshPage}><b>Create New Transaction</b></button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
