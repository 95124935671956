import React from 'react';
import '../App.css';
import 'bulma/css/bulma.min.css';
import { useHistory, useLocation } from 'react-router-dom';

function Cancel() {
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const transactionId = searchParams.get('transactionId')
    const totalAmount = searchParams.get('totalAmount')
    const message = searchParams.get('message')
    let history = useHistory();

    return(
        <main>
            {/* <img src="images/aqwire-logo.png" className="logo mt-1 mb-6" /> */}
            <div className="card">
            <div className="card-content">
                <div className="content">
                    <h3 className="title is-4"><center>Transaction Failed</center></h3>
                    <span className="tag is-warning is-light is-normal mb-3">{message}</span>
                    <table className="table is-fullwidth">
                        <tbody>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th className="indent-right">{transactionId}</th>
                                </tr>
                                <tr>
                                    <th>Total Amount</th>
                                    <th className="indent-right">{totalAmount}</th>
                                </tr>
                            </tbody>
                    </table>
                    <button className="button is-rounded is-fullwidth btn-color has-text-white" onClick={() => {history.push("/")}}>Create New Transaction</button>
                </div>
            </div>
            </div>
        </main>
    )
}
export default Cancel;